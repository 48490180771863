* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.main {
    height: 100%;
    width: 100%;
}

.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    margin: 0;
}

.react-datepicker-wrapper {
    flex: 3;
    border-bottom: 1px solid gray;
}

.react-datepicker__input-container input {
    border: none;
}