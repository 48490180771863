@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    background: #f9f9f9;
}

/* @media (max-width: 599px) {
   body { background-color: white;}
} */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.main {
    height: 100%;
    width: 100%;
}

.centered {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    margin: 0;
}

.react-datepicker-wrapper {
    -webkit-flex: 3 1;
            flex: 3 1;
    border-bottom: 1px solid gray;
}

.react-datepicker__input-container input {
    border: none;
}

/* // NOTE: the order of these styles DO matter\

// Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
    background: #e65474;
    color: white;
    opacity: 0.5;
    border: none;
    /* //default styles include a border */
}

/* // Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
    background: #CE2D4F;
    color: white;
}

/* // Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
    background: #CC111D;
    color: white;
}

/* // Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #e45761;
    color: white;
    border: none;
}

.SingleDatePickerInput,
.DateInput_input,
.SingleDatePickerInput_1,
.DateInput,
.DateInput_1,
.SingleDatePicker,.SingleDatePicker_1 {
    border: none;
    border-radius: 8px;
    box-shadow: inset 0px 1px 2px 0 rgba(0, 0, 0, 0.1);
    background: #eeeff3;
}

.SingleDatePicker, .SingleDatePicker_1 {
    /* width: 100%; */
    border-radius: 8px;
    border-radius: 8px;
}

/* .DateInput, .DateInput_1 {
    width: 80%;
} */

.SingleDatePickerInput, .SingleDatePickerInput_1 {
    border: 1px solid #b4bdd7;
    /* width: 100%; */
    border-radius: 8px;
    
}

.DateInput_input, .DateInput_input_1 {
    font-size: 1rem;
    /* width: 100%; */
    /* border-bottom-right-radius: 0;
    border-top-right-radius: 0; */
}

.DateRangePicker ,.DateRangePicker_1 {
    width: 100%;
    /* border: 2px solid #3A3335; */
    border-radius: 12px;
    background: #eeeff3;
}

.DateRangePickerInput,
.DateRangePickerInput_1 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-radius: 12px;
    background: #eeeff3;
}
.DateRangePickerInput input,
.DateRangePickerInput_1 input {
    padding: 0.75rem 0.5rem;
}

.DateRangePickerInput .DateInput,
.DateRangePickerInput .DateInput_1 {
    width: 47%;
    /* border-radius: 12px; */
    border: 1px solid #b4bdd7;
}
