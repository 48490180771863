
/* // NOTE: the order of these styles DO matter\

// Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
    background: #e65474;
    color: white;
    opacity: 0.5;
    border: none;
    /* //default styles include a border */
}

/* // Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
    background: #CE2D4F;
    color: white;
}

/* // Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
    background: #CC111D;
    color: white;
}

/* // Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #e45761;
    color: white;
    border: none;
}

.SingleDatePickerInput,
.DateInput_input,
.SingleDatePickerInput_1,
.DateInput,
.DateInput_1,
.SingleDatePicker,.SingleDatePicker_1 {
    border: none;
    border-radius: 8px;
    box-shadow: inset 0px 1px 2px 0 rgba(0, 0, 0, 0.1);
    background: #eeeff3;
}

.SingleDatePicker, .SingleDatePicker_1 {
    /* width: 100%; */
    border-radius: 8px;
    border-radius: 8px;
}

/* .DateInput, .DateInput_1 {
    width: 80%;
} */

.SingleDatePickerInput, .SingleDatePickerInput_1 {
    border: 1px solid #b4bdd7;
    /* width: 100%; */
    border-radius: 8px;
    
}

.DateInput_input, .DateInput_input_1 {
    font-size: 1rem;
    /* width: 100%; */
    /* border-bottom-right-radius: 0;
    border-top-right-radius: 0; */
}

.DateRangePicker ,.DateRangePicker_1 {
    width: 100%;
    /* border: 2px solid #3A3335; */
    border-radius: 12px;
    background: #eeeff3;
}

.DateRangePickerInput,
.DateRangePickerInput_1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    background: #eeeff3;
}
.DateRangePickerInput input,
.DateRangePickerInput_1 input {
    padding: 0.75rem 0.5rem;
}

.DateRangePickerInput .DateInput,
.DateRangePickerInput .DateInput_1 {
    width: 47%;
    /* border-radius: 12px; */
    border: 1px solid #b4bdd7;
}